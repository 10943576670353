/* tslint:disable:triple-equals */
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BackRouter} from '../modules/shared/services/back-router';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {IncomeChatComponent} from '../modules/shared/components/modals/income-chat/income-chat.component';
import {NGXLogger} from 'ngx-logger';
import {SendMessageEvent} from '../entity/send-message-event';
import {MessagesModalComponent} from '../modules/shared/components/modals/messages-modal/messages-modal.component';
import {ReaderService} from '../modules/shared/services/reader-service';
// tslint:disable-next-line:import-spacing
import {FeedbackAfterCallModalComponent} from '../modules/shared/components/modals/feedback-after-call-modal/feedback-after-call-modal.component';
import {Router} from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {AllEmiterService} from './all-emiter.service';
import {ChatService} from './nestjs/chat/chat.service';
import {environment} from '../../environments/environment';
import {WINDOW} from '../providers/window.provider';
import {SubSink} from 'subsink';
import {ElectronService} from './electron.service';
import {GoogleAnalyticsEventsService} from './google-analytics-events.service';
import {FeatureReadersModalComponent} from '../modules/shared/components/modals/feature-readers-modal/feature-readers-modal.component';
import * as Sentry from '@sentry/angular';
import {Socket, SocketIoConfig} from 'ngx-socket-io';
import {AlertService} from './alert.service';
import {isPlatformBrowser} from '@angular/common';
import {UtilService} from './util.service';
import {ChatEventDto} from '../modules/chat/events/chat-event.dto';
import {CurrentChatDto} from '../modules/chat/dto/current-chat.dto';
import {NotesModalComponent} from '../modules/shared/components/modals/notes-modal/notes-modal.component';
import {ConsultantService} from '../modules/consultant/services/consultant.service';

@Injectable({
    providedIn: 'root',
})
export class SocketIoService {
    public version: string = environment.appVersion;
    socket: Socket | null = null;
    private subs = new SubSink();
    public initialState = {
        time: 60,
        timer: null,
    };
    public messagesModalRef: BsModalRef;
    public incomeModalRef: BsModalRef;
    // onChangeAvailability = new EventEmitter();
    public newChatId = 0;
    // private socket: typeof Atmosphere = Atmosphere;
    messagesModalIsHidden = false;
    private chatRequestsAccepted = [];
    private userId = '';

    private  feedBackReadingId = null;
    responseProcessor = {
        ChatStateEvent: async (response) => {
            if (this.userService.data &&  this.userService.data.role === 'ROLE_CUSTOMER') {
                if (response.state === 'started') {
                    await this.gAE.sendEvent('reading', 'chat-start', response.chatId, null);
                }
            }
            // This is already done on ReadingEndEvent
        },
        OpenChatWindowEvent: async (response) => {
        },
        ChatNotActiveEvent: async (response) => {
            await this.chatService.sendChatKilled(this.userService.getData().userId);
        },
        ChatStateChangedEvent: async (response) => {
            await this.chatService.sendChatKilled(this.userService.getData().userId);
        },
        SendMessageEvent: async (response: SendMessageEvent) => {
            const self = this;
            if (environment.showNextReaderWhenReadingFails) {
                if (response.message.includes('hank you for trying to connect with')) {
                    this.messagesModalRef = this.bsModalService.show(FeatureReadersModalComponent, {
                        ignoreBackdropClick: false,
                        keyboard: true,
                        initialState: {message: response.message},
                        animated: false,
                    });

                }
                return;
            }
            if (!this.messagesModalRef) {
                this.messagesModalRef = this.bsModalService.show(MessagesModalComponent, {animated: false, });
                this.subs.sink = this.messagesModalRef.onHide.subscribe(function (hide) {
                    self.messagesModalRef = null;
                });
            }

            /**
             * If reader decline the call, we close the call confirmation layer
             */
            if (response.eventName === 'call.to.reader.failed') {
                this.allEmiterService.onCallToReaderFailed();
            }

            if (response.eventName === 'call.connected') {
                if (this.userService.data &&  this.userService.data.role === 'ROLE_CUSTOMER') {
                    await this.gAE.sendEvent('reading', 'call-start', null, null);
                }

            }

            /**
             * This is in case user add funds for the first time using Paypal.
             * When this happen I must send a message to the add funds layer to show the welcome message
             */

            if (response.message.includes('was added to your account')) {

                this.allEmiterService.onAddFundsFirstTime(true);
            }
            if (response.message.includes('PAYG was switched off')) {
                this.allEmiterService.onPaygSwitch(false);
            }
            if (response.message.includes('addFundsDialog.show()')) {
                // Change string to enable the link to add funds
                response.title = 'showAddFunds';
            }
            this.messagesModalRef.content.onMessage.emit(response);
            this.utilService.setTimeout(function () {
                try {
                    self.messagesModalRef.hide();
                } catch (e) {
                }
            }, 5000);

        },
        FeedbackPopupRequestEvent: (response) => {
            this.feedBackReadingId = response.readingId;
        },
        CloseChatIncomeInformerEvent: (response) => {
            this.sendChatAttemptEvent('CloseChatIncomeInformerEvent');
            if (this.incomeModalRef) {
                this.incomeModalRef.hide();
                this.incomeModalRef = null;
            }

        },
        ChatIncomeInformerEvent: (response) => {
                const self = this;
                // tslint:disable-next-line:max-line-length
                self.sendChatAttemptEvent('Showing notification desk');
                this.spawnNotification('Hey there! You\'ve been notified to join chat with ' + response.clientName + '!', 'assets/imagotipo.png', '<< Incoming Chat >>', response.chatId);
                self.sendChatAttemptEvent('Notification desk shown');
                if (this.incomeModalRef) {
                    self.sendChatAttemptEvent('Modal already created, emitting event');
                    this.incomeModalRef.content.timerEmitter.emit(response);
                    // return;
                }
                if (this.chatRequestsAccepted.length == 0 || !this.chatRequestsAccepted.find(chatId => chatId === response.chatId)) {
                    self.sendChatAttemptEvent('Chat request no accepted yet');
                    localStorage.setItem('readingId', response.readingId);
                    self.utilService.localStorageSetItem('ChatIncomeInformerEvent', 'true');
                    this.chatRequestsAccepted.push(response.chatId);
                    this.initialState.timer = response;
                    this.initialState.time = response.timerValue;
                    self.sendChatAttemptEvent('Show income chat component');
                    this.incomeModalRef = this.bsModalService.show(IncomeChatComponent, {
                        class: 'modal-sm income-chat-modal',
                        backdrop: 'static',
                        initialState: this.initialState,
                        animated: false,
                    });
                    try {
                        (document.getElementsByClassName('modal-backdrop')[0] as HTMLElement).style.background = '#351f3e url(/assets/ui-bg-diagonals-flashing-40x40.gif) 50% 50% repeat';
                        (document.getElementsByClassName('modal-backdrop')[0] as HTMLElement).style.opacity = '0.8';
                    } catch (e) {
                    }
                    this.incomeModalRef.content.timerEmitter.emit(response);

                    this.electronService.bounceDock('critical');
                    this.electronService.setBadgeText('New Chat');
                    this.incomeModalRef.content.onHide.subscribe(() => {
                        try {
                            (document.getElementsByClassName('modal-backdrop')[0] as HTMLElement).style.background = '#351f3e url(/assets/ui-bg-diagonals-flashing-40x40.gif) 50% 50% repeat';
                            (document.getElementsByClassName('modal-backdrop')[0] as HTMLElement).style.opacity = '0.8';
                        } catch (e) {
                        }
                        // Add the chatId to a list of ban to avoid multiple popUps
                        this.chatRequestsAccepted.push(response.chatId);
                        if (this.incomeModalRef !== null) {
                            this.incomeModalRef.hide();
                            this.incomeModalRef = null;
                        }

                    });
                } else {
                }

        },
        StartCustomerChatEvent: (response) => {
        },
        UserLogoutEvent: (response) => {
        },
        PageViewGAEvent: (response) => {
            this.gAE.pageView(response);
        },
        ECommerceGAEvent: async (response) => {
            // Now is done on the server, we leave this here in case we need angular does it
            if (response.customDimensions) {
                await this.gAE.eCommerceEvent(response.transactionId, this.window.location.host, response.revenue, response.currency,
                    response.product, response.productCode, response.referralKey, response.customDimensions[0],
                    response.customDimensions[1]);
                this.emitGAE(response);
            } else {
                await this.gAE.eCommerceEvent(response.transactionId, this.window.location.host, response.revenue, response.currency,
                    response.product, response.productCode, response.referralKey, false, false);
                this.emitGAE(response);
            }

        },
        CompleteRegistration: async (response) => {
            // It should not report events to GA
            // await this.gAE.completeRegistration();
            // this.emitGAE(response);
        },
        BonusEvent: async (response) => {
            /**
             * Update balance just if is a manual bonus event. This to avoid
             * showing user updates on balance when payg is trigger
             */
            if (response.type === 'NORMAL') {
                // Update funds
                await this.userService.getDetailsToPromise();
                // this.allEmiterService.onFundChange(response.balance);
            }

            // Already emmit on getDetails
            // this.allEmiterService.onFundChange(0);
            await this.gAE.bonusUsed(response.value, response.currency);
            // This will add info to the customer topUp Goal 15
            await this.gAE.sendEvent('balance', 'transaction', response.currency, response.value);
            this.emitGAE(response);
        },
        ReferralTrackingEvent: async (response) => {
            await this.gAE.referralTrackEvent(response);
            this.emitGAE(response);
        },
        ReadingEndEvent: async (response) => {
            // Notify a chat was rejected
            await this.userService.getDetailsToPromise();
            this.allEmiterService.onEndChat(0);
            // this.allEmiterService.onFundChange(0);
            // await this.chatService.sendChatKilled(this.userService.getData().userId);

        },
        FundsAddEvent: async (response) => {
            /**
             * Update balance just if is a manual event. This to avoid
             * showing user updates on balance when payg is trigger
             */
            if (response.type === 'NORMAL' || response.type === 'MANUAL') {
                // Update funds
                await this.userService.getDetailsToPromise();
                // this.allEmiterService.onFundChange(response.balance);
            }
        },
        PaypalFundsAddEvent: async (response) => {
            // Update funds
            await this.userService.getDetailsToPromise();
            this.allEmiterService.onFundChange(response.balance);
        },
        ReadingDeclinedEvent: async (response) => {
            this.allEmiterService.onDeclineChat();
            // @ts-ignore
            // window.chatDeclined();
            await this.updateBalance();
            // await this.chatService.sendChatDeclined(this.userService.getData().userId);
        },
        ReaderStateChangeEvent: (response: {
            objectType: string,
            id: number,
            readerId: string
        }) => {
            this.logger.debug('Reader State changed', response);
            try {
                const readerId = parseInt(response.readerId, 10);
                if (!isNaN(readerId)) {
                    if (this.userService.data && (
                        (this.userService.data.role === 'ROLE_READER' && this.userService.data.userId === readerId))) {
                        // this.onChangeAvailability.emit();
                    }
                }
                // @ts-ignore
                Sentry.addBreadcrumb({message: 'Getting reader profile because ReaderStateChangeEvent', event: response});
                this.readerService.getReaderById(readerId).subscribe((reader) => {
                    if (reader.available) {
                        this.electronService.setBadgeText('Available');
                    }
                    if (reader.busy) {
                        this.electronService.setBadgeText('Busy');
                    }
                    this.readerService.onUpdateReader.emit(reader);
                });

            } catch (e) {
            }

        },
        PaypalBonusEvent: async (response) => {
            // This will add info to the customer topUp Goal 15
            await this.gAE.sendEvent(response.category, 'transaction', response.currency, response.value);
            // This will add info to the customer Paypal TopUp (Goal 14)
            await this.gAE.sendEvent(response.category, response.event, response.currency, response.value);
        },
        NewIMail: async (response) => {
            this.allEmiterService.onNewIMail(response.threadId);
            this.alertService.alert({
                title: 'LifeMail',
                body: 'You have a new message, check your messages',
                type: 'info',
            });
            // Trick to show new message without calling a web service
            try {
                this.userService.data.newLifeMailNumber++;
            } catch (e) {
            }
        },
        CallAccepted: async (response) => {
            const customerId = response.customerId;
            const customerName = response.customerName;
            try {
                const data: any = await this.consultantService.getNotes(customerId).toPromise();
                const initialState = {
                    customerId,
                    customerName,
                    notes: data.notes,
                };
                this.modalService.show(NotesModalComponent, {
                    animated: false,
                    initialState: initialState,
                    class: 'notesModal',
                    backdrop: false,
                    ignoreBackdropClick: true
                });
            } catch (e) {
                this.logger.error(`Notes could not be loaded for customer (${customerId}) ${customerName} `);
            }

        },
        FirstTimeAddingFunds: async (response) => {
            await this.gAE.sendEvent('balance', 'first', response.currency, response.value);
        },
        FirstTimeAddingFundsEvent: async (response) => {
            await this.gAE.sendEvent('balance', 'first', response.currency, response.value);
        },
        RemoveSessionEvent: async (response) => {
            this.allEmiterService.onUserLogOut();
        }
    };

    private connected = false;
    private isBrowser: boolean = isPlatformBrowser(this.platformId);

    private wasDisconnected = false;

    private token = '';
    constructor(private httpClient: HttpClient,
                private br: BackRouter,
                private userService: UserService,
                private chatService: ChatService,
                private bsModalService: BsModalService,
                private readerService: ReaderService,
                private router: Router,
                private cookie: CookieService,
                private logger: NGXLogger,
                private allEmiterService: AllEmiterService,
                private electronService: ElectronService,
                private gAE: GoogleAnalyticsEventsService,
                private alertService: AlertService,
                private utilService: UtilService,
                private modalService: BsModalService,
                @Inject(WINDOW) private window: Window,
                @Inject(PLATFORM_ID) private platformId: Object,
                private consultantService: ConsultantService
                ) {
        const self = this;
        if (this.isBrowser) {
            this.token = this.utilService.localStorageGetItem('token');
            // Listen to the chatId when a request is coming
            if (this.allEmiterService.subsNewChat === undefined) {
                this.allEmiterService.subsNewChat = this.allEmiterService.invokeNewChat.subscribe((data) => {
                    self.newChatId = data;
                    // Open tab
                });
            }
            //
            // This must be here because customer can log in while on registration
            this.allEmiterService.subsLoggedIn = this.allEmiterService.invokeLoggedIn.subscribe((data) => {
                // In case user already connected but there is not token
                if (!self.token) {
                    self.token = this.utilService.localStorageGetItem('token');
                    const extraHeaders = {
                        Authorization: 'Bearer ' + self.token
                    };
                    const config: SocketIoConfig = { url: environment.socketIo.url, options: {
                            path: environment.socketIo.path,
                            extraHeaders
                        }};
                    self.socket = new Socket(config);
                }
                self.connect();
                self.init();

            });

            this.allEmiterService.subsUserLogOut = this.allEmiterService.invokeUserLogOut.subscribe((data) => {
                this.disconnect();
            });

            this.allEmiterService.subsFeedBack = this.allEmiterService.invokeFeedBack.subscribe(() => {
                this.showFeedBackPopup();
            });

            // this.init();
        }


    }

    showFeedBackPopup() {
        if (this.feedBackReadingId) {
            const initialState = {
                readingId: this.feedBackReadingId
            };
            this.bsModalService.show(FeedbackAfterCallModalComponent, {
                class: 'modal-sm',
                initialState: initialState,
                animated: false,
            });
            this.feedBackReadingId = null;
        }
    }

    connectOrGet(): any {
        if (this.isBrowser) {
            if (this.socket !== null && this.socket instanceof Socket) {
                return this.socket;
            } else {
                let extraHeaders = {};
                if (this.token) {
                    extraHeaders = {
                        Authorization: 'Bearer ' + this.token
                    };
                }
                // @ts-ignore
                const config: SocketIoConfig = { url: environment.socketIo.url, options: {
                        path: environment.socketIo.path,
                        extraHeaders,
                        // @ts-ignore
                        auth: {
                            token: 'Bearer ' + this.token
                        }
                    }};
                this.socket = new Socket(config);
                this.init();
                return this.socket;
            }
        }
    }

    init() {
        const self = this;

        this.socket.on('connect', function (e ) {
            // console.og('Connected to socket io');
            if (self.wasDisconnected) {
                // console.og('Reconnected');
                window.location.reload();
            }
            self.connect();
            self.allEmiterService.onConnectedTosocketIo();
        });

        this.socket.on('disconnect', function (e) {
            // console.og('Disconnect');
            self.connected = false;
            self.wasDisconnected = true;
        });
        this.socket.on('reconnect', function (e) {
            // console.og('Reconnected');
            this.window.location.reload();
        });
        this.socket.on('pushClient', function (e) {
            self.onMessage(e.objectType, e);
        });
        /**
         * This event will be listen by the reader and it is trigger by the customer
         */
        this.socket.on('chat-requested', function (e) {
            localStorage.setItem('readingId', e.readingId);
            try {
                self.sendChatAttemptEvent('chat requested');
                self.sendChatAttemptEvent(JSON.stringify(e));
                self.responseProcessor['ChatIncomeInformerEvent'](e);
            } catch (err) {
                self.sendChatAttemptEvent('Error chat requested');
                console.error('Error chat requested', err);
                console.error(e);
            }
        });


    }

    /**
     * This would not stablish the connection to socket-io. Will notify about the user id for messages
     */
    connect() {
        const self = this;
        try {
            // console.og('connected socket io', this.connected);
            if (!this.connected) {
                // console.og('isLoggedIn', this.userService.isLoggedIn());
                if (this.userService.isLoggedIn()) {
                    self.userId = 'user_' + this.userService.getData().userId;
                    // console.og('user_', self.userId);
                    // Var required for Google tag manager
                    // @ts-ignore
                    this.window.atmosphereEventUrl = this.br.backendUrl + `/event/default/${self.userId}`;
                    this.socket.emit('userLog', self.userId, async function (response) {
                        // console.og('socket io User log');
                        self.connected = true;
                        self.utilService.localStorageSetItem('firstTime', 'false');
                    });
                }
            }
        } catch (e) {
        }
    }

    connectToChat(readingId: number, userId: number, userRole: string, onChat, callback) {
        // console.og('Connect to chat', readingId, userId, userRole);
        const self = this;
        this.socket.emit('joinChat', {readingId, userId, userRole}, function (response) {
            self.socket.on('chat', function (eventDto: ChatEventDto) {
                // // console.og(`Event readingId ${eventDto.readingId} vs current readingId ${readingId}`);
                // tslint:disable-next-line:no-unused-expression
                    if (eventDto.readingId == readingId) {
                        onChat(eventDto);
                    } else {
                        // console.error(readingId, eventDto.readingId);
                        // console.error(eventDto);
                        // tslint:disable-next-line:max-line-length
                        // self.sendChatEvent(readingId, self.userService.getData().userId, `WRONG_ANGULAR_EVENT ON ${readingId} (${eventDto.readingId}) ` + eventDto.event);
                    }

            });
            callback();
        });
    }

    notifyReader(currentChat: CurrentChatDto) {
        this.socket.emit('chat-requested', currentChat);
    }

    finishChat(currentChat: CurrentChatDto) {
        this.socket.emit('chat-requested', currentChat);
    }

    /**
     * This will emit the event chat-message that is being listen by the nestjs server
     * It is sent directly to nestjs because nestjs needs to save the message on the db
     * and report java about it
     * @param readingId
     * @param userId
     * @param message
     */
    sendChatMessage(readingId: number, chatId: number, userId: number, userRole: string, message: string, messageId: number, lang: string) {
        this.socket.emit('chat-message', {readingId, chatId, userId, userRole, message, messageId, lang});
    }


    async sendChatEvent(readingId: number, userId: number, event: string, userRole: string) {
        await this.socket.emit('chat-event', {readingId, userId, event, userRole});
    }

    async createChatAttemptEvent(event: string) {
        const readingId = localStorage.getItem('readingId');
        await this.socket.emit('chat-attempt-event', {readingId, event, version: this.version});
    }

    sendChatAttemptEvent(event: string) {
        const readingId = localStorage.getItem('readingId');
        this.logger.debug('sendChatAttemptEvent ', event, readingId);
        try {
            this.socket.emit('chat-attempt-event', {readingId, event, version: this.version});
        } catch (e) {
        }
    }


    async userFinishChat(readingId: number, userId: number, userRole: string) {
        await this.emit('user-finish-chat', {readingId, userId, userRole});

    }

    async disconnectChat(readingId: number, userId: number, userRole: string) {
        await this.emit('disconnect-chat', {readingId, userId, userRole});

    }

    async messageReceived(readingId: number, userId: number, messageId: number, chatId: number) {
        await this.emit('chat-message-received', {readingId, userId, messageId, chatId});

    }

    /**
     * This will emit the event reader join that is being listen by the the customer and reader to start the chat
     * @param readingId
     */
    async readerJoin(readingId: number, ) {
        await this.emit('reader-join', {readingId});

    }

    async userTyping(readingId: number, userId: number, isTyping: boolean) {
        // // console.og('Sending user typing');
        this.socket.emit('chat-typing', {readingId, userId, isTyping});
    }

    private async emit(event, data) {
        const promise =  new Promise((resolve, reject) => {
            if (!this.socket) {
                reject('No socket connection.');
            } else {
                this.socket.emit(event, data, (response) => {
                    if (response.error) {
                        console.error(response.error);
                        reject(response.error);
                    } else {
                        resolve(data);
                    }
                });
            }
        });
        return await promise;
    }

    disconnect() {
        const self = this;
        try {
            if (!this.connected) {
                if (this.userService.isLoggedIn()) {
                    const id = 'user_' + this.userService.getData().userId;
                    this.socket.emit('logOut', id, function () {
                        self.connected = false;
                    });
                }
            }
        } catch (e) {
        }

    }

    /**
     * Avoid multiple notifications for same chat
     * @param theBody
     * @param theIcon
     * @param theTitle
     * @param id
     */
    spawnNotification(theBody, theIcon, theTitle, id) {
        const self = this;
        const options = {
            body: theBody,
            icon: theIcon,
            tag: id
        };
        const n = new Notification(theTitle, options);
        self.utilService.setTimeout(n.close.bind(n), 5000);
    }



    onMessage(event, response) {
        try {
           this.logger.debug('New socket event', event, response);
            this.responseProcessor[event](response);
        } catch (e) {
            throw e;
        }
    }



    emitGAE(data) {
        // this.socket.emit('gae', { host: location.host, user: this.userId, data: data } );
    }

    private async updateBalance() {
        // await this.userService.getDetailsToPromise();
        this.allEmiterService.onEndChat(0);
        // this.allEmiterService.onFundChange(0);
    }



}
