<div class="modal-header">
    <button (click)="confirmClose()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- size-detector.component.html -->
    <app-size-detector></app-size-detector>
    <div class="steps">
        <div class="step active">Create Account</div>
        <div class="step">Personal Info</div>
        <div class="step">Start Reading</div>
    </div>

    <div class="social-auth">
        <div class="desc">
            <b>Connect using</b>
            <p>Nothing will be shared</p>
            <p>via your social neworks</p>
        </div>
        <div class="icons">
            <span *ngIf="hasRegisteredUsingFacebook===undefined" class="fa fa-facebook-official"></span>
            <ng-container *ngIf="facebookLoaded">
                <span (click)="getDataFacebook()" *ngIf="hasRegisteredUsingFacebook===true"
                      class="fa fa-facebook-official"></span>
                <span (click)="submitLoginFB()" *ngIf="hasRegisteredUsingFacebook===false"
                      class="fa fa-facebook-official"></span>
            </ng-container>
            <ng-container *ngIf="!facebookLoaded">
                <span class="fa fa-facebook-official fb-disabled"></span>
            </ng-container>
<!--            <span (click)="getDataFacebook()" *ngIf="hasRegisteredUsingFacebook===true"-->
<!--                  class="fa fa-facebook-official"></span>-->
<!--            <span (click)="submitLoginFB()" *ngIf="hasRegisteredUsingFacebook===false"-->
<!--                  class="fa fa-facebook-official"></span>-->
        </div>
    </div>

    <div class="hr"></div>

    <div class="form-container">
        <form (ngSubmit)="doSubmit()" [formGroup]="form" id="lifereader">
            <div *ngIf="error" class="alert alert-danger">
                {{error}}
            </div>
            <div [ngClass]="{'has-error':hasError('userName')}" class="form-group">
                <label for="username">Username</label>
                <input (focusout)="lostFocus()" maxlength="20" (input)="userNameInput($event)" [appAutoFocus]="size > 0 ? true: false" autocomplete="off" class="form-control"
                       formControlName="userName"
                       id="username" type="text">
                <div *ngIf="hasError('userName') && checkError('userName', 'required', omitError) && !omitError"
                     class="text-danger help-block">
                    <small>Please enter a Username</small>
                </div>
                <div *ngIf="hasError('userName') && checkError('userName', 'minlength', omitError)"
                     class="text-danger help-block">
                    <small>Username: Minimum 6 characters</small>
                </div>
                <div *ngIf="hasError('userName') && checkError('userName', 'pattern')" class="text-danger help-block">
                    <small>Username: contains illegal characters, please only use letters and number characters,
                        \".-_+\" and
                        whitespace
                    </small>
                </div>
            </div>
            <div [ngClass]="{'has-error':hasError('email')}" class="form-group">
                <label for="email">Email</label>
                <input (focusout)="lostFocus()" autocomplete="email" class="form-control" formControlName="email"
                       id="email"
                       required type="email">
                <div *ngIf="hasError('email') && checkError('email', 'email')" class="text-danger help-block">
                    <small>E-Mail: Please enter a valid email address</small>
                </div>
                <div *ngIf="hasError('email') && checkError('email', 'required')" class="text-danger help-block">
                    <small>Please enter an e-mail address</small>
                </div>
            </div>

            <div [ngClass]="{'has-error':hasError('password')}" class="form-group">
                <label for="password">Password</label>
                <input (focusout)="lostFocus()" autocomplete="new-password" class="form-control"
                       formControlName="password"
                       id="password" type="password">
                <div *ngIf="hasError('password') && checkError('password', 'required')" class="text-danger help-block">
                    <small>Password: Please choose a Password</small>
                </div>
                <div *ngIf="hasError('password') && checkError('password', 'minlength')" class="text-danger help-block">
                    <small>Password: Your password is not secure enough. It has to be at least 8 characters long. Please
                        try
                        again
                    </small>
                </div>
                <div *ngIf="hasError('password') && checkError('password', 'maxlength')" class="text-danger help-block">
                    <small>Password: Maximum "24" characters</small>
                </div>
                <div *ngIf="hasError('password') && checkError('password', 'strong')" class="text-danger help-block">
                    <small>Password must contain letters and numbers</small>
                </div>
            </div>

            <div class="form-group submit-section">
                <button class="btn btn-success col-xs-12 col-sm-5" type="submit"><span *ngIf="loading"
                                                                                       class="fa fa-spinner fa-pulse"></span>
                    Create Account
                </button>
                <div class="clearfix"></div>

            </div>

        </form>
    </div>

    <div class="question">
        <b>Already have an account?</b>
        <a (click)="doLogin()">Sign in</a>
    </div>

    <div class="policy-section">
        <div class="policy">
            <div class="policy-logo">
                <img src="assets/images/SSLcertificate.png"/>
            </div>
            <div class="desc">
                <p>
                    *You will receive email communications from LifeReader. You can unsubscribe within your account or
                    via a link
                    at the end of each mail.<br>
                    I'm 18 years or older. By registering I confirm that I have read, understood and agree to
                    LifeReaders
                    <a (click)="bsModalRef.hide()" routerLink="/terms-and-conditions">Terms & Conditions</a> and <a
                        (click)="bsModalRef.hide()" routerLink="/privacy">Privacy Policy</a>.
                </p>
            </div>
        </div>
    </div>

</div>
