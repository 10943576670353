<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- size-detector.component.html -->
    <app-size-detector></app-size-detector>
    <div class="social-auth">
        <div class="desc">
            <b>Connect using</b>
            <p>Nothing will be shared</p>
            <p>via your social neworks</p>
        </div>
        <div class="icons">
            <ng-container *ngIf="facebookLoaded">
                <span (click)="getDataFacebook()" *ngIf="hasLoggedUsingFacebook===true"
                      class="fa fa-facebook-official"></span>
                <span (click)="loginFacebook()" *ngIf="hasLoggedUsingFacebook===false"
                      class="fa fa-facebook-official"></span>
            </ng-container>
            <ng-container *ngIf="!facebookLoaded">
                <span class="fa fa-facebook-official fb-disabled"></span>
            </ng-container>

        </div>
    </div>

    <div class="hr"></div>

    <div *ngIf="errorMessage" class="alert alert-danger">
        {{errorMessage | unescape}}
    </div>

    <div class="form-container">
        <form #formDir="ngForm" (ngSubmit)="doSubmit()" [formGroup]="form">
            <div [ngClass]="{'has-error':hasError('email')}" class="form-group">
                <label [ngClass]="hasError('email') ? 'red-color-font': ''" for="email">Email</label>
                <input [appAutoFocus]="size > 0 ? true: false" class="form-control" formControlName="email" id="email" type="email">
                <div *ngIf="hasError('email') && checkError('email', 'serverSide')"
                     class="text-danger alert alert-danger">
                    {{getErrorMessage('email', 'serverSide')}}
                </div>
            </div>
            <div [ngClass]="{'has-error':hasError('password')}" class="form-group">
                <label [ngClass]="hasError('password') ? 'red-color-font': ''" for="password">Password</label>
                <input class="form-control" formControlName="password" id="password" type="password">
                <div *ngIf="hasError('password') && checkError('password', 'serverSide')"
                     class="text-danger alert alert-danger">
                    {{getErrorMessage('password', 'serverSide')}}
                </div>
            </div>
            <div class="form-group submit-section">
                <button (click)="doLogin()" class="btn btn-success col-xs-12 col-sm-3">Sign In</button>
                <div class="clearfix"></div>
            </div>
        </form>
    </div>

    <div class="question">
        <b>No account yet?</b>
        <a (click)="doRegistration()">Register Free</a>
    </div>

    <div class="policy-section">
        <div class="policy">
            <div class="policy-logo">
                <img src="assets/images/SSLcertificate.png"/>
            </div>
            <div class="desc">
                <p>By signing I confirm that I have read, understood and agree to LifeReaders
                    <a (click)="bsModalRef.hide()" routerLink="/terms-and-conditions">Terms & Conditions</a> and
                    <a (click)="bsModalRef.hide()" routerLink="/privacy">Privacy Policy</a> and that I will receive
                    communications from LifeReader.
                </p>
            </div>
        </div>
    </div>

    <div class="forgot-section">
        <a (click)="openModalForgotPassword()" class="pointer">Forgot your Password?</a>
    </div>
</div>
