<div class="modal-header">
    <button (click)="bsModalRef.hide()" aria-label="Close" class="close" type="button">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- size-detector.component.html -->
    <app-size-detector></app-size-detector>
    <div class="steps">
        <div class="step complete">Create Account</div>
        <div class="step active">Personal Info</div>
        <div class="step">Start Reading</div>
    </div>
    <p>This information is required for account verification purposes.</p>
    <p>No personal data will be shared.</p>
    <div class="col-sm-offset-1 col-sm-10">
        <form (submit)="doSubmit()" [formGroup]="form">
            <div class="row">
                <div class="col-xs-12 col-sm-3 padding-zero">
                    <div [ngClass]="{'has-error':hasError('firstName')}" class="form-group">
                        <label>First Name</label>
                        <input [value]="firstName" [appAutoFocus]="size > 0 ? true: false" class="form-control" formControlName="firstName"
                               type="text">
                        <div *ngIf="hasError('firstName') && checkError('firstName', 'required')"
                             class="text-danger help-block">
                            <small>Please enter a  valid first name</small>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-3 padding-zero">
                    <div [ngClass]="{'has-error':hasError('lastName')}" class="form-group">
                        <label>Last Name</label>
                        <input [value]="lastName" class="form-control" formControlName="lastName" type="text">
                        <div *ngIf="hasError('lastName') && checkError('lastName', 'required')"
                             class="text-danger help-block">
                            <small>Please enter a  valid last name</small>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6">
                    <div
                            [ngClass]="{'has-error':hasError('birthDateDay') || hasError('birthDateMonth') || hasError('birthDateYear')} "
                            class="form-group">
                        <label>Birth Date</label>
                        <div class="">
                            <div class="col-xs-4 col-sm-4 padding-zero">
                                <select class="form-control" formControlName="birthDateDay">
                                    <option disabled selected value="">
                                        Day
                                    </option>
                                    <option *ngFor="let day of getDays()" [ngValue]="day">{{day}}</option>
                                </select>
                                <div *ngIf="hasError('birthDateDay') && checkError('birthDateDay', 'required')"
                                     class="text-danger help-block">
                                    <small>Day not valid</small>
                                </div>
                            </div>
                            <div class="col-xs-4 col-sm-4 padding-zero">
                                <select class="form-control" formControlName="birthDateMonth">
                                    <option disabled selected value="">
                                        Month
                                    </option>
                                    <option *ngFor="let month of getMonths();let i = index" [ngValue]="i+1">{{month}}
                                    </option>
                                </select>
                                <div *ngIf="hasError('birthDateMonth') && checkError('birthDateMonth', 'required')"
                                     class="text-danger help-block">
                                    <small>Month not valid</small>
                                </div>
                            </div>
                            <div class="col-xs-4 col-sm-4 padding-zero">
                                <select class="form-control" formControlName="birthDateYear">
                                    <option disabled selected value="">
                                        Year
                                    </option>
                                    <option *ngFor="let year of getYears()" [ngValue]="year">{{year}}</option>
                                </select>
                                <div *ngIf="hasError('birthDateYear') && checkError('birthDateYear', 'required')"
                                     class="text-danger help-block">
                                    <small>Year not valid</small>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4 padding-zero">
                    <div [ngClass]="{'has-error':hasError('address')}" class="form-group">
                        <label>Address</label>
                        <input class="form-control" formControlName="address" type="text">
                        <div *ngIf="hasError('address') && checkError('address', 'required')"
                             class="text-danger help-block">
                            <small>Address not valid</small>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-3  padding-zero">
                    <div [ngClass]="{'has-error':hasError('zip')}" class="form-group">
                        <label>Post Code</label>
                        <input class="form-control" formControlName="zip" type="text">
                        <div *ngIf="hasError('zip') && checkError('zip', 'required')"
                             class="text-danger help-block">
                            <small>Post code not valid</small>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-5" [ngClass]="size>0 ? '': 'padding-zero'">
                    <div [ngClass]="{'has-error':hasError('defaultPhoneCountry')}" class="form-group">
                        <label>Country
                        </label>
                        <select class="form-control" formControlName="defaultPhoneCountry"
                                (change)="phoneCountryCodeChange()">
                            <option disabled selected value="">
                                Select Country
                            </option>
                            <option *ngFor="let item of countries" [ngValue]="item.id">{{item.name}}</option>
                        </select>
                        <div *ngIf="hasError('defaultPhoneCountry') && checkError('defaultPhoneCountry', 'required')"
                             class="text-danger help-block">
                            <small>Country not valid</small>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-4 padding-zero">
                    <div [ngClass]="{'has-error':hasError('defaultPhoneNumber')}" class="form-group">
                        <label>
                            Phone
                            <small>(optional)</small>
                        </label>
                        <div class="input-group">
              <span class="input-group-addon">
                <span *ngIf="!phoneCountryCode">+XX</span>
                <span
                        *ngIf="phoneCountryCode">{{phoneCountryCode}}</span>
              </span>
                            <input
                                    class="form-control"
                                    (input)="validatePhoneNumber(phoneCountryCode)"
                                    [readOnly]="!phoneCountryCode"
                                    formControlName="defaultPhoneNumber"
                                    type="tel">
                        </div>
                        <div *ngIf="hasError('defaultPhoneNumber')" class="help-block">
                            {{getErrorMessage('defaultPhoneNumber', 'serverSide')}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row continue-button">
                <button class="btn btn-success" [disabled]="!form.valid">Continue</button>
            </div>
        </form>
    </div>
    <div class="clearfix"></div>

</div>
