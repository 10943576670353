import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ServerErrors} from '../../helpers/server-errors';
import {FormHelper} from '../../helpers/form.helper';
import {NGXLogger} from 'ngx-logger';
import {finalize} from 'rxjs/operators';
import {CookieService} from 'ng2-cookies';
import {RegistrationResponse} from '../../../../entity/registration-response';
import {RegistrationStep2Component} from '../registration-step2/registration-step2.component';
import {AuthService} from '../../../../services/auth.service';
import {BirthDate} from '../../../../entity/birth-date';
import {LoginResponse} from '../../../../entity/login-response';
import {AudioModalComponent} from '../modals/audio-modal/audio-modal.component';
import {UserService} from '../../../../services/user.service';
import {Router} from '@angular/router';
import {SubSink} from 'subsink';
import {AllEmiterService} from '../../../../services/all-emiter.service';
import {BackRouter} from '../../services/back-router';
import {LoginFormComponent} from '../login-form/login-form.component';
import {SCREEN_SIZE} from '../../../../screen-size.enum';
import {ResizeService} from '../../../../services/resize.service';
import {GoogleAnalyticsEventsService} from '../../../../services/google-analytics-events.service';
import {UtilService} from '../../../../services/util.service';
import {WINDOW} from '../../../../providers/window.provider';
import {environment} from '../../../../../environments/environment';
import {PasswordValidator} from '../../../../validators/password.validator';
import {RegistrationLeaveModalComponent} from '../modals/registration-leave-modal/registration-leave-modal.component';

declare var FB: any;

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent extends ServerErrors implements OnInit, OnDestroy {
    form: FormGroup;
    omitError = false;
    doSignIn: EventEmitter<boolean> = new EventEmitter();
    loading = false;
    error;
    hasRegisteredUsingFacebook = undefined;
    private subs = new SubSink();
    size: SCREEN_SIZE;
    facebookLoaded = false;
    constructor(
        public bsModalRef: BsModalRef,
        private fb: FormBuilder,
        private authService: AuthService,
        private logger: NGXLogger,
        private cookie: CookieService,
        private userService: UserService,
        private bsModalService: BsModalService,
        private router: Router,
        // private atmosphereService: AtmosphereService,
        private allEmiterService: AllEmiterService,
        private br: BackRouter,
        private resizeSvc: ResizeService,
        private utilService: UtilService,
        private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        @Inject(WINDOW) private window: Window,
        private cookieService: CookieService
    ) {
        super();
    }

    ngOnInit() {
        const self = this;
        this.buildForm();
        self.facebookLoginStatus();
        this.omitError = true;
        this.subs.sink = this.resizeSvc.onResize$.subscribe(x => {
            this.size = x;
        });
    }

    facebookLoginStatus() {
        const self = this;
        // Check if user has logged in previously
        try {
            FB?.getLoginStatus(function (response) {
                self.facebookLoaded = true;
                if (response.status === 'connected') {
                    self.hasRegisteredUsingFacebook = true;
                } else {
                    self.hasRegisteredUsingFacebook = false;
                }
            });
        } catch (e) {
            console.error(e);
            self.utilService.setTimeout(function () {
                self.facebookLoginStatus();
            }, 500);

        }
    }

    buildForm() {
        let  random = '';
        let  randomEmail = '';
        let  randomPass = '';
        if (environment.test.randomData) {
            random = Math.random().toString(36).slice(6, 12);
            randomEmail = Math.random().toString(36).slice(6, 12) + '@lr.com';
            randomPass = Math.random().toString(36).slice(0, 8);
        }
        // console.og('randomPass', randomPass);
        this.form = this.fb.group({
            'email': [randomEmail, Validators.compose([
                Validators.required,
                Validators.email,
            ])],
            'userName': [random, Validators.compose([
                Validators.required,
                Validators.minLength(6),
                Validators.pattern('^[a-zA-Z0-9 \\\\-\\\\+_]+$')
            ])],
            'password': [randomPass, Validators.compose([
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(24),
                PasswordValidator.strong
            ])],
            'rememberMe': [true],
            'referralKey': '',
            app: [false]
        });
    }

    doSubmit() {
        FormHelper.touchedForm(this.form);
        if (!this.form.valid) {
            return false;
        }
        const self = this;
        this.loading = true;
        // Check if there is a referral cookie
        const referral = this.cookieService.get('referral');
        const referralData = this.cookieService.get('referral-data');
        if (referral && referralData) {
            this.form.patchValue({
                referralKey: referral + '&&&' + referralData
            });
        }

        this.subs.sink = this.authService.doRegistrationStep1(this.form.value).pipe(finalize(() => {
            this.loading = false;
        })).subscribe(async (res: RegistrationResponse) => {
            if (res.toString()) {
                // Remove the referral cookies
                this.cookieService.delete('referral');
                this.cookieService.delete('referral-data');
                self.utilService.localStorageSetItem('userDetails', JSON.stringify(res.userDetails));
                self.utilService.localStorageSetItem('uid', res.userDetails.userId + '');
                self.utilService.localStorageSetItem('token', res.token);

                // Get details of the user that register to be able to connect to  atmosphere
                try {
                    await self.userService.getDetails();
                    await self.googleAnalyticsEventsService.userFinishFirstStep(res.userDetails.userId);
                    self.utilService.localStorageSetItem('registration', 'step2');
                } catch (e) {
                    self.logger.error('Error getting user details');
                    self.logger.error(e);
                }

                try {
                    // @ts-ignore
                    if (window.PasswordCredential) {
                        try {
                            const l = {
                                id: this.form.controls['email'].value,
                                password: this.form.controls['password'].value,
                            };
                            // @ts-ignore
                            const c = new PasswordCredential(l);
                            // @ts-ignore
                            await navigator.credentials.store(c);
                        } catch (e) {
                            console.error('Credentials were not saved');
                            console.error(e);
                        }
                    } else {
                        self.logger.info('browser credentials no enable');
                    }
                } catch (e) {
                    self.logger.error('Error browser credentials', e);
                }
                // On some private modes the system does not detect the new log in, so I will refresh and show step 2
                const url = self.window.location + '?registration=true';
                self.window.location.href = url;
                // this.bsModalRef.hide();
                // this.bsModalService.show(RegistrationStep2Component, {
                //     class: 'modal-dialog-steps'
                // });
            } else {
                console.error('no data');
            }
        }, (err) => {
            self.utilService.localStorageRemoveItem('registration');
            if (err.status === 400) {
                this.error = err.error.message;
            }
            return false;
        });
        return false;
    }

    getDataFacebook() {
        this.omitError = true;
        const self = this;
        // FB.login();
        FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                FB.api(
                    '/me?fields=id,name,gender,about,address,age_range,birthday,email,education,' +
                    'first_name,last_name,location,relationship_status,short_name,work',
                    function (response2) {
                        self.processFacebookData(response2);
                    },
                );
                return;
            } else {
                self.submitLoginFB();
            }
        });
    }

    submitLoginFB() {
        this.omitError = true;
        const self = this;
        // FB.login();
        FB.login(function (response) {
            if (response.authResponse) {
                FB.api(
                    '/me?fields=id,name,gender,about,address,age_range,birthday,email,education,' +
                    'first_name,last_name,location,relationship_status,short_name,work',
                    function (response2) {
                        self.processFacebookData(response2);
                    },
                );
                return;
            }

        }, {scope: 'email'});
    }

    processFacebookData(response2) {
        // response2.email = 'anewtest@lr.com';
        // tslint:disable-next-line:no-bitwise
        const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
        const self = this;
        if (response2 && !response2.error) {
            let birthday = response2.birthday;
            const dob: BirthDate = new BirthDate();
            if (birthday) {
                birthday = birthday.split('/');
                try {
                    const day: number = parseInt(birthday[0], 10);
                    dob.day = day;
                } catch (e) {
                    dob.day = 1;
                }
                try {
                    const month: number = parseInt(birthday[1], 10);
                    dob.month = month;
                } catch (e) {
                    dob.month = 1;
                }
                try {
                    const year: number = parseInt(birthday[2], 10);
                    dob.year = year;
                } catch (e) {
                    dob.year = 1991;
                }
            } else {
                dob.day = 1;
                dob.month = 1;
                dob.year = 1991;
            }
            if (dob.month > 12) {
                const aux = dob.month;
                dob.month = dob.day;
                dob.day = aux;
            }
            response2.name = response2.name.split(' ')[0] + '* ' + hashCode(response2.last_name);
            // Check if there is a referral cookie
            const referral = this.cookieService.get('referral');
            const referralData = this.cookieService.get('referral-data');
            let referralKey = '';
            if (referral && referralData) {
                referralKey = referral + '&&&' + referralData;
            }
            this.subs.sink = self.userService.doLoginFB({
                email: response2.email,
                lastName: response2.last_name,
                firstName: response2.first_name,
                gender: response2.gender + ' ',
                dob: dob,
                displayName: response2.name,
                referralKey,
                app: false
            }).subscribe(async (loginResponse: LoginResponse) => {
                    if (loginResponse) {
                        self.cookie.set('JSESSIONID', loginResponse.jsessionId);
                        self.utilService.localStorageSetItem('token', loginResponse.token);
                        this.allEmiterService.onLoggedIn();
                        self.bsModalRef.hide();
                    }
                    if (self.userService.getRole() === 'ROLE_READER') {
                        self.bsModalService.show(AudioModalComponent, {
                            animated: false,
                            class: 'modal-sm',
                            backdrop: 'static',
                            keyboard: false,
                            ignoreBackdropClick: true
                        });
                    }

                    if (self.userService.getRole() === 'ROLE_ADMIN') {
                        // self.router.navigate(['/admin']);
                       this.window.location.replace('https://www.dev.lifereader.com/admin/livestats');
                        return;
                    }

                    try {
                        this.subs.sink = this.userService.getDetails().subscribe(() => {
                            if (this.userService.isLoggedIn()) {
                                // self.atmosphereService.connect();
                                self.allEmiterService.onLoggedIn();
                            }
                        }, (e) => {
                            if (e.message !== 'No user data') {
                                throw e;
                            }
                        });
                    } catch (e) {
                        console.error(e);
                    }
                },
                (err: any) => {
                    if (err.status === 400) {
                        this.error = err.error.message;
                    }
                    this.subs.sink = self.userService.doLoginFB({
                        email: response2.email,
                        lastName: response2.last_name,
                        firstName: response2.first_name,
                        gender: response2.gender + ' ',
                        dob: dob,
                        displayName: response2.name,
                        referralKey,
                        app: false
                    }).subscribe(async (loginResponse: LoginResponse) => {
                            if (loginResponse) {
                                self.cookie.set('JSESSIONID', loginResponse.jsessionId);
                                self.utilService.localStorageSetItem('token', loginResponse.token);
                                this.allEmiterService.onLoggedIn();
                                self.bsModalRef.hide();
                            }
                            if (self.userService.getRole() === 'ROLE_READER') {
                                self.bsModalService.show(AudioModalComponent, {
                                    animated: false,
                                    class: 'modal-sm',
                                    backdrop: 'static',
                                    keyboard: false,
                                    ignoreBackdropClick: true
                                });
                            }

                            if (self.userService.getRole() === 'ROLE_ADMIN') {
                                // self.router.navigate(['/admin']);
                               this.window.location.replace('https://www.dev.lifereader.com/admin/livestats');
                                return;
                            }

                            try {
                                this.subs.sink = this.userService.getDetails().subscribe(() => {
                                    self.utilService.localStorageRemoveItem('registration');
                                    if (this.userService.isLoggedIn()) {
                                        // self.atmosphereService.connect();
                                        self.allEmiterService.onLoggedIn();
                                    }
                                }, (e) => {
                                    if (e.message !== 'No user data') {
                                        throw e;
                                    }
                                });
                            } catch (e) {
                                console.error(e);
                            }
                            const initialState = {
                                firstName: response2.first_name,
                                lastName: response2.last_name,
                                gender: response2.gender,
                                dob: dob
                            };
                            this.bsModalService.show(RegistrationStep2Component, {
                                animated: false,
                                class: 'modal-dialog-steps',
                                initialState: initialState,
                                backdrop: true
                            });
                        },
                        (err2: any) => {
                            self.utilService.localStorageRemoveItem('registration');
                            if (err2.status === 400) {
                                this.error = err2.error.message;
                            }
                        });
                });
        }
    }

    getImage() {
        return this.br.backendUrl + '/2016/static/img/comodo-logo.png';
    }

    doLogin() {
        this.omitError = true;
        this.bsModalRef.hide();
        // https://lifereader.atlassian.net/browse/LA-1021
        this.bsModalService.show(LoginFormComponent, {
            animated: false,
            backdrop: true,
            keyboard: false
        });
        // this.doSignIn.emit(true);
    }

    confirmClose() {
        const self = this;
        this.omitError = true;
        this.bsModalRef.hide();
        // const registrationLeaveModal = this.bsModalService.show(RegistrationLeaveModalComponent, {
        //     animated: false,
        //     backdrop: true,
        //     keyboard: true
        // });
        // this.subs.sink = registrationLeaveModal.content.onConfirm.subscribe((value) => {
        //     if (value === true) {
        //         self.bsModalRef.hide();
        //     }
        // });

    }

    /**
     * This function is to change the value of omitError with a delay.
     * This with the purpose of avoid show the error messages when user try to click
     * in some areas
     */
    lostFocus() {
        const self = this;
        if (self.omitError) {
            self.utilService.setTimeout(function () {
                self.omitError = false;
            }, 500);
        }

    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    removeSpecialChars(str: string) {
        return str.replace(/[^a-zA-Z0-9 ]/g, '');
    }

    userNameInput($event: Event) {
        let userName = this.form.value.userName;
        userName = this.removeSpecialChars(userName);
        this.form.controls.userName.setValue(userName);
    }
}
