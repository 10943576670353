import {Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ReaderService} from '../../../services/reader-service';
import {NGXLogger} from 'ngx-logger';
import {AllEmiterService} from '../../../../../services/all-emiter.service';
import {UserService} from '../../../../../services/user.service';
import {SubSink} from 'subsink';
import {BackRouter} from '../../../services/back-router';
import {BroadcastChannel} from 'broadcast-channel';
import {ChatService} from '../../../../../services/nestjs/chat/chat.service';
import {CookieService} from 'ng2-cookies';
import {environment} from '../../../../../../environments/environment';
import {UtilService} from '../../../../../services/util.service';
import {WINDOW} from '../../../../../providers/window.provider';
import {SocketIoService} from '../../../../../services/socket-io.service';
import {Router} from '@angular/router';
import {AlertService} from '../../../../../services/alert.service';
import {Exception} from 'twilio-client/es5/twilio/util';
import * as Sentry from '@sentry/angular';
@Component({
    selector: 'app-income-chat',
    templateUrl: './income-chat.component.html',
    styleUrls: ['./income-chat.component.scss'],
})
export class IncomeChatComponent implements OnInit, OnDestroy {

    public clientName: string;

    public time = 60;

    public timer: {
        chatId: number,
        clientName: String,
        gender: String,
        timerValue: number,
        readingId: number,
    };

    public onHide = new EventEmitter();

    public timerEmitter = new EventEmitter();

    public channel = new BroadcastChannel('lifeReader');

    public coutdown;

    // Timer for playing the beep
    private beepTimer;

    private subs = new SubSink();

    private newWindow = null;

    private beepAlert = null;

    chatRequestAccepted = false;

    constructor(
        public bsModalRef: BsModalRef,
        private readerService: ReaderService,
        private logger: NGXLogger,
        private allEmiterService: AllEmiterService,
        private userService: UserService,
        private chatService: ChatService,
        private utilService: UtilService,
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private socketIoService: SocketIoService,
    ) {
    }

    async ngOnInit() {
        this.utilService.localStorageSetItem('onChatPage', 'false');
        this.chatRequestAccepted = false;
        const self = this;
        this.subs.sink = this.timerEmitter.subscribe((timer) => {
            this.socketIoService.sendChatAttemptEvent('Timer emitter');
            this.timer = timer;
            this.socketIoService.sendChatAttemptEvent(JSON.stringify(timer));
            this.clientName = timer.clientName;
            if (this.time <= 0) {
                // this.doHide();
            }
        });

        // this.subs.sink = this.readerService.chatInformer(this.timer.chatId).subscribe((res) => {
        // });
        await this.chatService.informerRaised(this.timer.chatId);
        this.subs.sink = this.allEmiterService.subsEndChat = this.allEmiterService.invokeEndChat.subscribe((chatId) => {
            this.socketIoService.sendChatAttemptEvent('Invoke end chat emitter');
            if (chatId === this.timer.chatId) {
                self.doHide();
            }

        });
        this.channel.onmessage = function (e) {
            if (e && e.cmd) {
                switch (e.cmd) {
                    case 'incomeChat':
                        self.onHide.emit();
                        break;
                }
            } else {
                self.logger.error('Channel on message not valid', e);
            }

        };
        this.subs.sink = this.coutdown = self.utilService.setInterval(function () {
            self.time = self.time - 1;
            if (self.time <= 0) {
                self.doHide();

            }
        }, 1000);

        this.subs.sink = this.beepTimer = self.utilService.setInterval(function () {
            self.playSound();
        }, 3000);
        this.playSound();

    }

    playSound() {
        try {
            if (!this.beepAlert) {
                this.beepAlert = new Audio('assets/flashbeep_alert.mp3');
            }
            this.beepAlert.load();
            // @ts-ignore
            Sentry.addBreadcrumb('Trying to play sound in income chat component');
            this.beepAlert.play();
        } catch (e) {
            this.logger.error('Sound could not be play');
            throw e;
        }
    }

    doDecline() {
        this.subs.sink = this.readerService.chatDecline(this.timer.chatId).subscribe(async (res) => {
            await this.chatService.chatDeclined(this.timer.readingId);
            await this.doHide();
        });
    }

    async doAccept() {
        this.socketIoService.sendChatAttemptEvent('doAccept');
        try {
            this.socketIoService.sendChatAttemptEvent(this.timer.toString());
            this.socketIoService.sendChatAttemptEvent(JSON.stringify(this.timer));
        } catch (e) {
            console.error('Error login events');
            console.error(this.timer);
        }
        this.chatRequestAccepted = true;
        this.allEmiterService.onNewChat(this.timer.chatId);

        try {
            // const res = await this.readerService.chatAccept(this.timer.chatId).toPromise();
            this.socketIoService.sendChatAttemptEvent('Chat Accepted');
            await this.chatService.chatAccepted(this.timer.readingId);
            this.socketIoService.sendChatAttemptEvent('Send chat event');
            try {
                this.socketIoService.sendChatEvent(
                    this.timer.readingId, this.userService.getData().userId,
                    'ACCEPTING CHAT', 'Reader').catch((err) => {
                    // I do not need to log anything here, the chat can continue
                });
            } catch (e) {
                console.error('Error sending event');
            }
            /**
             * There is a problem where reader click on accept, but they are no redirect. Checking the logs for the readingId 1159999
             * we notice that we get the message ACCEPTING_CHAT but user click several times on it.
             * To avoid multiple clicks we can disable the button, but if after a while the user is not redirect we can refresh the page
             * */
            this.utilService.setTimeout(() => {
                this.socketIoService.sendChatAttemptEvent('TimeOut to redirect reader');
                // If after 5 seconds user is not on the chat page, we refresh the page, that might solve the problem
                const onChatPage = this.utilService.localStorageGetItem('onChatPage');
                if (onChatPage && onChatPage !== 'true') {
                    this.utilService.localStorageSetItem('prevUrl', this.router.url);
                    this.window.location.replace('chat');
                }
            }, 5000);
            // Save reading on local storage, so we can rejoin the chat in case the user refresh browser
            this.socketIoService.sendChatAttemptEvent('Setting current reader');
            this.utilService.localStorageSetItem('currentReading', JSON.stringify(this.timer));
            // await this.utilService.delay(environment.test.delayRedirectChat);
            this.socketIoService.sendChatAttemptEvent('Navigate to chat page');
            // Redirect user to the chat module
            const self = this;
            /**
             * For an unknow reason, navigateByUrl return an error when is call for the first time after a release.
             * To avoid the error, we can it with a timeout
             */
            setTimeout(() => {
                self.utilService.localStorageSetItem('prevUrl', self.router.url);
                self.router.navigateByUrl('chat', {
                    state: {
                        currentChat: self.timer
                    }
                }).then(() => {
                    self.socketIoService.sendChatAttemptEvent('Already navigate');
                }).catch((e) => {
                    self.logger.error('Error navigating');
                    self.socketIoService.sendChatAttemptEvent('Error navigating to chat page');
                    self.logger.error(e);
                });
            });
            this.chatRequestAccepted = false;
            this.socketIoService.sendChatAttemptEvent('Do hide income chat');
            await this.doHide();
            this.socketIoService.sendChatAttemptEvent('Income chat hidden');
        } catch (e) {
            this.socketIoService.sendChatAttemptEvent('Error accepting chat');
            this.logger.error('Error accepting chat');
            console.error(e);
            this.logger.error(e);
            this.logger.error(this.timer);
            try {
                await this.socketIoService.sendChatEvent(
                    this.timer.readingId,
                    this.userService.getData().userId,
                    'ERROR ACCEPTING CHAT ' + e.toString(),
                    'Reader');
            } catch (e) {
                this.logger.error('Error sending event');
            }
            await this.doDecline();
        }

    }


    async doHide() {
        const self = this;
        self.utilService.localStorageRemoveItem('ChatIncomeInformerEvent');
        // clearInterval(this.coutdown);
        self.utilService.clearInterval(this.coutdown);
        this.onHide.emit();
        // Report to the other tabs that the popup must be closed
        try {
            await this.channel.postMessage({cmd: 'incomeChat', data: 'hide'});
        } catch (e) {
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
