/* tslint:disable:max-line-length */
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CanActivateUser} from './resolvers/can-activate-user';
import {ReaderApplicationComponent} from './components/reader-application/reader-application.component';
import {AdminResolver} from './resolvers/admin-resolver';
import {ReaderRegistrationStep2Component} from './components/reader-registration-step2/reader-registration-step2.component';
import {ReadersCategoryResolverService} from './resolvers/readers-category-resolver.service';
import {GeneralResolver} from './resolvers/general-resolver';
import {ReaderProfileResolverService} from './resolvers/reader-profile-resolver.service';

// @ts-ignore
const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
        // resolve: {
        //     info: AdminResolver,
        // },
        data: { title: 'LifeReader' }
    },
    {
        path: 'dispatcher/rest/v1/credit_card/update/success',
        loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
        data: { title: 'LifeReader' }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'credit_card/create/success',
        loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
        data: { title: 'LifeReader' }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'credit_card/create/fail',
        loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
        data: { title: 'LifeReader' }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'credit_card/update/success',
        loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
        data: { title: 'LifeReader' }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'credit_card/update/fail',
        loadChildren: () => import('./components/main/main.module').then(m => m.MainModule),
        data: { title: 'LifeReader' }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'app/credit_card/create/success',
        loadChildren: () => import('./modules/shared/components/cc-mobile-app/cc-mobile-app.module').then(m => m.CcMobileAppModule),
        data: { title: 'LifeReader', showHeader: false, showFooter: false, chatRunning: true }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'app/credit_card/create/fail',
        loadChildren: () => import('./modules/shared/components/cc-mobile-app/cc-mobile-app.module').then(m => m.CcMobileAppModule),
        data: { title: 'LifeReader', showHeader: false, showFooter: false, chatRunning: true }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'app/credit_card/update/success',
        loadChildren: () => import('./modules/shared/components/cc-mobile-app/cc-mobile-app.module').then(m => m.CcMobileAppModule),
        data: { title: 'LifeReader', showHeader: false, showFooter: false, chatRunning: true }
        // resolve: {
        //     info: AdminResolver,
        // },
    },
    {
        path: 'app/credit_card/update/fail',
        loadChildren: () => import('./modules/shared/components/cc-mobile-app/cc-mobile-app.module').then(m => m.CcMobileAppModule),
        data: { title: 'LifeReader', showHeader: false, showFooter: false, chatRunning: true }
        // resolve: {
        //     info: AdminResolver,
        // },
    },

    {
        path: 'become-reader',
        loadChildren: () => import('./components/become-reader/become-reader.module').then(m => m.BecomeReaderModule),
        data: { title: 'LifeReader' },
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'apply/reader-step1',
        component: ReaderApplicationComponent,
        resolve: {
            info: GeneralResolver,
        },
        data: { title: 'LifeReader' }
    },
    {
        path: 'how-it-works',
        loadChildren: () => import('./modules/hiw/hiw.module').then(m => m.HiwModule),
        resolve: {
            info: GeneralResolver,
        },
        data: { title: 'How it works' }
    },
    {
        path: 'contact',
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
        resolve: {
            info: GeneralResolver,
        },
        data: { title: 'Contact' }
    },
    {
        path: 'all-psychic-readers-at-lifereader',
        loadChildren: () => import('./modules/readers/readers.module').then(m => m.ReadersModule),
        resolve: {
            info: ReadersCategoryResolverService
        },
        // data: { title: 'All Readers' }
    },
    {
        path: 'psychics-clairvoyants',
        loadChildren: () => import('./modules/readers/readers.module').then(m => m.ReadersModule),
        resolve: {
            info: ReadersCategoryResolverService
        },
        // data: { title: 'Psychics Clairvoyants' }
    },
    {
        path: 'tarot-reading',
        loadChildren: () => import('./modules/readers/readers.module').then(m => m.ReadersModule),
        resolve: {
            info: ReadersCategoryResolverService
        },
        // data: { title: 'Tarot Reading' }
    },
    {
        path: 'astrology-horoscopes',
        loadChildren: () => import('./modules/readers/readers.module').then(m => m.ReadersModule),
        resolve: {
            info: ReadersCategoryResolverService
        },
        // data: { title: 'Astrology Horoscopes' }
    },
    {
        path: 'love-relationships',
        loadChildren: () => import('./modules/readers/readers.module').then(m => m.ReadersModule),
        resolve: {
            info: ReadersCategoryResolverService
        },
        // data: { title: 'Love Relationships' }
    },
    {
        path: 'mind-body-spirit',
        loadChildren: () => import('./modules/readers/readers.module').then(m => m.ReadersModule),
        resolve: {
            info: ReadersCategoryResolverService
        },
        // data: { title: 'Mind Body Spirit' }
    },
    {
        path: 'help',
        loadChildren: () => import('./modules/help/help.module').then(m => m.HelpModule),
        resolve: {
            info: GeneralResolver,
        },
        data: { title: 'LifeReader Help' }
    },
    {
        path: 'psychic-phone-readings',
        loadChildren: () => import('./modules/psychic-phone-readings/psychic-phone-readings.module').then(m => m.PsychicPhoneReadingsModule),
        resolve: {
            info: AdminResolver,
        },
        data: {
            header: false,
            footer: false
        }
    },
    {
        path: 'psychic-chat-readings',
        loadChildren: () => import('./modules/psychic-chat-readings/psychic-chat-readings.module').then(m => m.PsychicChatReadingsModule),
        resolve: {
            info: AdminResolver,
        },
        data: {
            header: false,
            footer: false
        }
    },
    {
        path: 'psychic-readings',
        loadChildren: () => import('./modules/psychic-readings/psychic-readings.module').then(m => m.PsychicReadingsModule),
        resolve: {
            info: AdminResolver,
        },
        data: {
            header: false,
            footer: false
        }
    },
    {
        path: 'love-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver
        }
    },
    {
        path: 'free-weekend-love-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'free-monthly-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'free-daily-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'free-yesterday-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'free-today-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'free-tomorrow-horoscope',
        loadChildren: () => import('./modules/horoscope/horoscope.module').then(m => m.HoroscopeModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'psychic-reader/:reader',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        data: {
        },
        resolve: {
            info: ReaderProfileResolverService,
        },
    },
    {
        path: 'consultant',
        loadChildren: () => import('./modules/consultant/consultant.module').then(m => m.ConsultantModule),
        data: {
            roles: ['ROLE_READER', 'ROLE_CUSTOMER'],
        },
        canActivate: [CanActivateUser],
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'customer',
        loadChildren: () => import('./modules/customer/customer.module').then(m => m.CustomerModule),
        data: {
            roles: ['ROLE_CUSTOMER'],
        },
        canActivate: [CanActivateUser],
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'review-guide',
        loadChildren: () => import('./modules/shared/components/review-guide/review-guide.module').then(m => m.ReviewGuideModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'privacy',
        loadChildren: () => import('./modules/shared/components/privacy/privacy.module').then(m => m.PrivacyModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'terms-and-conditions',
        loadChildren: () => import('./modules/shared/components/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'reset/process',
        loadChildren: () => import('./modules/shared/components/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'reader-terms',
        loadChildren: () => import('./modules/shared/components/reader-terms/reader-terms.module').then(m => m.ReaderTermsModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'customer-terms',
        loadChildren: () => import('./modules/shared/components/customer-terms/customer-terms.module').then(m => m.CustomerTermsModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'sitemap',
        loadChildren: () => import('./modules/shared/components/site-map/site-map.module').then(m => m.SiteMapModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'cookies',
        loadChildren: () => import('./modules/shared/components/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule),
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'apply/reader-step2',
        component: ReaderRegistrationStep2Component,
        resolve: {
            info: GeneralResolver,
        },
    },
    {
        path: 'chat',
        loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
        data: { showHeader: false, showFooter: false, chatRunning: true }
    },
    {path: '**', redirectTo: '/', pathMatch: 'full'},

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64],
        relativeLinkResolution: 'legacy',
        initialNavigation: 'enabled',
    }),
    ],
    exports: [RouterModule],
})

export class AppRoutingModule {

}
